.red-cell {
    color: red;
}

.cell-purple {
    background-color: #440540;
    color: #440540;
}

.cell-green {
    background-color: #04580d;
    color: #04580d;
}

.cell-yellow {
    background-color: #e4ce08;
    color: #e4ce08;
}

.cell-red {
    background-color: #ce0a00;
    color: #ce0a00;
}

.cell-gray {
    background-color: gray;
    color: gray;
}

.cell-blue {
    background-color: #00074f;
    color: #00074f;
}

.cell-brown {
    background-color: #c37d75;
    color: #c37d75;
}

.normal-cell {
    color: inherit;
}

/* Defina a cor de fundo da tabela como branco */
.ag-theme-alpine .ag-root-wrapper {
    background-color: #e4feec6c;
}
