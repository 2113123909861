.fade-out {
    animation: fadeOut 1s forwards;
    width: 100%;
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
