.red-cell {
    color: red;
}

.normal-cell {
    color: inherit;
}

/* Defina a cor de fundo da tabela como branco */
.ag-theme-alpine .ag-root-wrapper {
    background-color: #e4feec6c;
}
